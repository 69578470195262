'use strict';

angular.module('enervexSalesappApp').factory('BaffleVoltage', function ($resource) {
	return $resource('/api/baffle-voltages/:id', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		}
	});
});
